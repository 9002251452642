<template>
  <div>
    <div class="tab-bar">
      <div
        v-for="(item, n) in orderState"
        :key="`${item.state}_${n}`"
        @click="changePayStateTabBar(item.state)"
        :class="{
          active: item.state == listQuery.state,
        }"
      >
        <span>
          {{ item.name }}
          <span class="dot" v-if="item.showDot"></span>
        </span>
      </div>
    </div>

    <div class="filter-panel">
      <CSSelect style="width: 210px; margin-right: 10px">
        <el-date-picker
          v-model="workOrderQueryParam.startTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          placeholder="请选择开始时间"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <span
        style="
          display: inline-block;
          vertical-align: middle;
          margin: 0 10px 15px 0;
          height: 2px;
          width: 20px;
          margin-right: 0;
          background-color: #999;
        "
      >
      </span>
      <CSSelect style="margin-right: 20px; margin-left: 10px; width: 210px">
        <el-date-picker
          v-model="workOrderQueryParam.endTime"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          type="datetime"
          placeholder="请选择结束时间"
          :editable="false"
        >
        </el-date-picker>
      </CSSelect>
      <CSSelect style="padding-left: 5px">
        <select v-model="workOrderQueryParam.type" style="color: #000">
          <option value="">全部服务提供方</option>
          <option
            v-for="(itemName, itemKey) in itemType"
            :key="itemKey"
            :value="itemKey"
          >
            {{ itemName }}
          </option>
        </select>
      </CSSelect>
      <input
        type="text"
        class="cs-input"
        style="margin-right: 30px"
        v-model="listQuery.userInfo"
        placeholder="搜索用户信息/服务提供方信息"
      />
      <button class="btn btn-primary" @click="handleFilter">查询</button>
    </div>

    <div class="result-panel">
      <!-- 当用户点击付款时显示 -->
      <template v-if="listQuery.state == 1">
          <CSTable :thead-top="filterHeight">
              <template v-slot:header>
                  <div class="table-header-panel" >
                      <span class="mr-2">
                        累计已付金额：{{ list.totalPaid != null ? list.totalPaid : "-" }}元
                      </span>
                                  <span>
                        累计收益：{{
                                          list.totalEarning != null ? list.totalEarning : "-"
                                      }}元
                      </span>
                  </div>
              </template>
              <template v-slot:thead>
                  <tr>
                      <th class="text-center">付款时间</th>
                      <th class="text-center">项目名称</th>
                      <th class="text-center">应付金额</th>
                      <th class="text-center">已付金额</th>
                      <th class="text-center">抽成比例</th>
                      <th class="text-center">抽成收益</th>
                      <th class="text-center">服务提供方</th>
                      <th class="text-center">用户信息</th>
                      <th class="text-center">支付渠道</th>
                      <th class="text-center" style="width: 100px">备注</th>
                  </tr>
              </template>
              <template v-slot:tbody>
                  <tr v-for="(log, index1) in list.data" :key="index1">
                      <td class="text-center">
                          {{ log.paymentTime || "-" }}
                      </td>
                      <td class="text-center">
                          <div>
                              {{ log.itemName || "-" }}
                          </div>
                      </td>
                      <td class="text-center">
                          {{ log.payableMoney || "-" }}
                      </td>
                      <td class="text-center">
                          {{ log.paymentMoney || "-" }}
                      </td>
                      <td class="text-center">{{ log.commissionScale || "-" }}%</td>
                      <td class="text-center">
                          <template v-if="log.commissionEarnings != null">
                              {{ log.commissionEarnings }}
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td class="text-center">
                          <p style="margin-bottom: 10px">
                              {{ log.providerName || "" }}
                          </p>
                          <p style="margin-bottom: 0">
                              {{ log.providerContact || "" }}
                              {{ log.providerPhone || "" }}
                          </p>
                      </td>
                      <td class="text-center">
                          <p style="margin-bottom: 10px">
                              {{ log.userCompany || "" }}
                          </p>
                          <p style="margin-bottom: 10px">
                              {{ log.userName || "" }}
                              {{ log.userPhone || "" }}
                          </p>
                          <p style="margin-bottom: 0">
                              {{ log.userCompanyAddr || "" }}
                          </p>
                      </td>
                      <td class="text-center">
                          <p v-if="log.payChannel != null">
                              {{ payOpts[log.payChannel] || "" }}
                          </p>
                          <p style="margin-bottom: 0">
                              {{ log.paymentOrderNo || "" }}
                          </p>
                      </td>
                      <td class="text-center">
                  <span
                      class="allow-click"
                      v-if="log.comment && log.comment != ''"
                      @click="
                      refundcommentBox = true;
                      refundcommentBoxText = log.comment;
                    "
                  >
                    查看
                  </span>
                          <template v-else>-</template>
                      </td>
                  </tr>
              </template>
          </CSTable>
        <Pagination name="pagination" component="pagination"></Pagination>
      </template>

      <!-- 当用户点击退款时显示 -->
      <template v-if="listQuery.state == 2">
          <CSTable :thead-top="filterHeight">
              <template v-slot:header>
                  <div class="table-header-panel">
                      累计已退金额：{{ list.totalRefund ? list.totalRefund : "-" }}元
                  </div>
              </template>
              <template v-slot:thead>
                  <tr>
                      <th class="text-center">退款时间</th>
                      <th class="text-center">项目名称</th>
                      <th class="text-center">应付金额</th>
                      <th class="text-center">已付金额</th>
                      <th class="text-center">已退金额</th>
                      <th class="text-center">退款原因</th>
                      <th class="text-center">退款人</th>
                      <th class="text-center">抽成比例</th>
                      <th class="text-center">抽成收益</th>
                      <th class="text-center">服务提供方</th>
                      <th class="text-center">用户信息</th>
                      <th class="text-center">支付渠道</th>
                      <th class="text-center" style="width: 100px">备注</th>
                  </tr>
              </template>
              <template v-slot:tbody>
                  <tr v-for="(log, index) in list.data" :key="index">
                      <td class="text-center">
                          {{ log.refundTime || "-" }}
                      </td>
                      <td class="text-center">
                          {{ log.itemName || "-" }}
                      </td>
                      <td class="text-center">
                          {{ log.payableMoney || "-" }}
                      </td>
                      <td class="text-center">
                          {{ log.paymentMoney || "-" }}
                      </td>
                      <td class="text-center">
                          {{ log.refundMoney || "-" }}
                      </td>
                      <td class="text-center">
                  <span
                      class="allow-click"
                      v-if="log.refundCause && log.refundCause !== ''"
                      @click="
                      refundBox = { visible: true, reason: log.refundCause }
                    "
                  >
                    查看
                  </span>
                          <template v-else> - </template>
                      </td>
                      <td class="text-center">
                          <p>
                              {{ log.refundName || "" }}
                              【{{ log.refundDepartment || "-" }}】
                          </p>
                      </td>
                      <td class="text-center">{{ log.commissionScale || "-" }}%</td>
                      <td class="text-center">
                          <template v-if="log.commissionEarnings != null">
                              {{ log.commissionEarnings }}
                          </template>
                          <template v-else>-</template>
                      </td>
                      <td class="text-center">
                          <p>
                              {{ log.providerName || "-" }}
                          </p>
                          <p>
                              {{ log.providerContact || "" }}
                              {{ log.providerPhone || "" }}
                          </p>
                      </td>
                      <td class="text-center">
                          <p>
                              {{ log.userCompany || "" }}
                          </p>
                          <p>
                              {{ log.userName || "-" }}
                              {{ log.userPhone || "" }}
                          </p>
                          <p>
                              {{ log.userCompanyAddr || "" }}
                          </p>
                      </td>
                      <td class="text-center">
                          <p v-if="log.payChannel != null">
                              {{ payOpts[log.payChannel] || "-" }}
                          </p>
                          <p>
                              {{ log.paymentOrderNo || "-" }}
                          </p>
                      </td>
                      <td class="text-center">
                  <span
                      class="allow-click"
                      v-if="log.comment && log.comment != ''"
                      @click="
                      refundcommentBox = true;
                      refundcommentBoxText = log.comment;
                    "
                  >
                    查看
                  </span>
                          <template v-else>-</template>
                      </td>
                  </tr>
              </template>
          </CSTable>

        <Pagination name="pagination" component="pagination"></Pagination>
      </template>
      <!-- <Pagination name="pagination" component="pagination"></Pagination> -->
    </div>

    <!-- 付款   查看备注的弹出框 -->
    <CSDialog
      dialogTitle="备注"
      dialogWidth="480px"
      dialog-header-class="alert-bg"
      :dialogVisible="refundcommentBox"
      @onClose="refundcommentBox = false"
      :dialogShowConfirmBtn="false"
      dialogCancelBtnText="关闭"
    >
      <div
        slot="dialog-content"
        style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        {{ refundcommentBoxText }}
      </div>
    </CSDialog>

    <!-- 退款   查看退款原因的弹出框 -->
    <CSDialog
      dialogTitle="退款原因"
      dialogWidth="480px"
      dialog-header-class="alert-bg"
      :dialogVisible="refundBox.visible"
      @onClose="refundBox.visible = false"
      dialogCancelBtntext="关闭"
      :dialogShowConfirmBtn="false"
    >
      <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
        <label style="width: 120px; margin-right: 40px">退款原因</label>
        <i>{{ refundBox.reason || "-" }}</i>
      </div>
    </CSDialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
// 导入接口文档
import { getfinanceQueryUrl } from "@/requestUrl";
import { RED_DOT_TYPES } from "../../common/commonConst";
import { getShowRedDot } from "@/utils/index";
import CSTable from "@/components/common/CSTable";
import dayjs from "dayjs";

export default {
  props: {},
  components: {
      CSTable,
    Pagination,
    CSSelect,
    CSDialog,
  },
  data() {
    return {
      typeOpts: {
        1: "上门维修",
        2: "气窗开启",
      },
        filterHeight: 0,
      payOpts: {
        0: "微信",
        1: "支付宝",
        2: "现金支付",
        3: "银行转账",
      },
      ticketType: {
        0: "上门维修",
      },
      listQuery: {
        state: 1,
        userInfo: "",
        handler: "",
      },
      money: 1,
      handlerOpts: 1,
      // handlerOpts: [
      //   {
      //     label: "",
      //     value: "",
      //   },
      // ],
      itemType: {
        1: "第三方",
        2: "物业",
      },
      total: 11,
      orderState: [
        { state: 1, name: "付款", showDot: false },
        { state: 2, name: "已退款", showDot: false },
      ],
      // 获取到的页面数据
      list: { data: [] },
      workOrderQueryParam: {
        startTime: new Date(dayjs().subtract(30, "day").valueOf()),
        endTime: new Date(),
        regionId: "",
        type: "",
        state: 1,
        origin: "",
        isDuplicate: "",
        isTimeout: "",
        keyword: "",
        score: "",
        lastId: null,
      },
      refundcommentBox: false,
      refundcommentBoxText: "",
      // 控制退款弹出框显示与隐藏
      refundBox: {
        visible: false,
        reason: "",
      },
      // hot小红点显示与隐藏
      hidden: true,
    };
  },
  created() {
    this.getList();
    window.addEventListener("keydown", this.handleFilterDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleFilterDown);
  },
  deactivated(){
    window.removeEventListener("keydown", this.handleFilterDown);
  },
  mounted() {
    this.changeTabBar(this.workOrderQueryParam.state);
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.getList(pageNo);
    });
    this.$vc.on(this.$route.path, "dot", "enterpriseBill", (params) => {
      this.$set(this.orderState[params.index], "showDot", params.isShow);
    });
      this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      window.addEventListener('resize', () => {
          this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
      })
  },
  methods: {
    handleFilterDown(e) {
      if (e.keyCode === 13) {
        this.handleFilter();
      }
    },
    changePayStateTabBar(state) {
      this.listQuery.state = state;
      this.handleFilter();
      this.hidden = false;
    },
    handleFilter() {
      this.getList();
    },
    //   获取企业服务账单信息
    getList(pageNo = 1, pageSize = 10) {
      this.$fly
        .post(getfinanceQueryUrl, {
          state: this.listQuery.state, //1:已支付；2.已退款  必填
          startDate: dayjs(this.workOrderQueryParam.startTime).format(
            "YYYY-MM-DD HH:mm"
          ), //必填
          endDate: dayjs(this.workOrderQueryParam.endTime).format(
            "YYYY-MM-DD HH:mm"
          ), //必填
          userInfo: this.listQuery.userInfo,
          itemType: this.workOrderQueryParam.type,
          regionId: this.$vc.getCurrentRegion().communityId, //必填 区域id
          pageNo,
          pageSize,
        })
        .then((res) => {
          if (this.listQuery.state == 1) {
            getShowRedDot(this, RED_DOT_TYPES.ENT_BILL_PAYMENT);
          } else {
            getShowRedDot(this, RED_DOT_TYPES.ENT_BILL_REFUND);
          }
          if (pageNo === 1 && res.data && Object.keys(res.data).length > 0) {
            this.$vc.emit(this.$route.path, "pagination", "init", {
              total: res.data.total,
              currentPage: pageNo,
              pageSize: res.data.total ? pageSize : res.data.data.length,
            });
          }
          this.list = res.data;
        });
    },

    // 时间函数
    changeTabBar(state) {
      this.workOrderQueryParam.lastTimeOrderIds = [null];
      this.workOrderQueryParam = {
        startTime: new Date(
          dayjs(
            dayjs().subtract(30, "day").format("YYYY-MM-DD") + "00:00:00"
          ).valueOf()
        ),
        endTime: new Date(
          dayjs(dayjs().format("YYYY-MM-DD") + "23:59:59").valueOf()
        ),
        regionId: this.$vc.getCurrentRegion().communityId,
        type: "",
        state: state,
        origin: "", // 1: 用户提交 2:客服提交
        isDuplicate: "", // 0:未重复  1:重复
        isTimeout: "", // 0:未超时  1: 超时
        keyword: "",
        score: "", // 评分
        lastId: null,
        paymentState: "", //选填 支付状态 0.待支付；1.已支付；2.支付超时；3.已退款
      };
      if (state == 1) {
        this.workOrderQueryParam.origin = 2;
      }
    },
    addWorkOrder() {
      let componentMethod = "addWorkOrder";
      if (this.addWorkOrderParams.ticketId != null) {
        componentMethod = "editWorkOrder";
      }
      this.$vc.http.post(
        "workOrder",
        componentMethod,
        JSON.stringify(this.addWorkOrderParams),
        {
          emulateJSON: true,
        },
        (resText, res) => {
          this.informantStartTime = resText;
          console.log(this.informantStartTime, 9999999999999);
          this.changeAddWorkOrderModal(false);
          this.$vc.toast(res.body.code == 0 ? "操作成功" : res.body.msg);
          this.changeTabBar(this.workOrderQueryParam.state);
          console.log(res.body, "965564654564564565555555555555555");
        },
        (errText, err) => {
          //console.log(err, "错误信息99999999999999");
        }
      );
    },
  },
};
</script>

<style scoped>
.refundBox_ .el-dialog__header {
  padding: 0 !important;
}
.refundBox_ .el-dialog div {
  margin-bottom: 40px;
}
.refundBox_ .el-dialog__body {
  padding: 10px 20 5px 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: #000;
}
.refundBox_ .el-dialog__footer {
  border-top: 1px solid #999;
  text-align: center !important;
  padding-top: 20px;
}
.refundBox_ .el-dialog div {
  margin-bottom: 20px;
}

.refundReason_ .el-dialog__header {
  padding: 0 !important;
}
.refundReason_ .el-dialog div {
  margin-bottom: 40px;
}
.refundReason_ .el-dialog__body {
  padding: 10px 20 5px 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: #000;
}
.refundReason_ .el-dialog__footer {
  border-top: 1px solid #999;
  text-align: center !important;
  padding-top: 20px;
}
.refundReason_ .el-dialog div {
  margin-bottom: 20px;
}
.operation .dropdown-menu {
  transform: translate3d(-40px, 35px, 0px) !important;
  z-index: 9999 !important;
}

.el-card__body {
  padding-left: 0;
  padding-right: 0;
}
</style>

<style scoped>
.el-table-column {
  text-align: center;
}
i {
  font-style: normal;
}
.el-tabs {
  margin: 20px 0;
}
.btn-secondary {
  margin: 20px 0;
}
.el-table {
  text-align: center;
}
.el-table-column {
  text-align: center;
}
.form-group {
  display: inline;
  margin-right: 20px;
}
.el-card {
  margin-bottom: 20px;
}
.line {
  margin-right: 20px;
}
.form-tip {
  margin-left: 10px;
}
.dialog-footer .el-button {
  margin-right: 130px;
}
.el-dialog span {
  margin-right: 20px;
}
.el-dialog div {
  margin: 10px 0;
}
.el-dialog .remarks {
  margin-left: 14px;
}
.col-lg-12 button {
  border: 0;
}
.item {
  position: relative;
  left: 55px;
  top: -10px;
}
.el-button:hover {
  color: #6a8bf6;
  text-decoration: underline;
}
.success {
  position: absolute;
  top: 100px;
  left: 1070px;
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background-color: #18a689;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
}

.paddingCenter {
  height: 40px;
  /* position: relative;
      top: 5px;
      left: 0; */
}
/* 圆角边框 */

.col-lg-12 > .btn:nth-child(1) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-lg-12 > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.nav {
  margin-bottom: 20px;
  padding-left: 30px;
}
.line {
  margin: 0 20px 0 0px;
}
.form-group {
  margin-right: 20px;
}
.el-dialog #refundTitle {
  width: 80px !important;
  text-align: right;
  padding-left: 15px;
}
.btn_close {
  width: 70px;
  height: 40px;
  color: #fff;
  background: #ff9f00;
  box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
  border-radius: 6px;
  margin-right: 20px;
}
.btn_affirm {
  width: 70px;
  height: 40px;
  color: #fff;
  background: #1ab394;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
  border-radius: 6px;
}
.el-dialog input {
  width: 80px;
  padding-left: 5px;
  border: 1px solid #999;
  border-radius: 2px;
}
.el-dialog textarea {
  width: 720px;
  height: 200px;
  border: none;
  background-color: #f0f0f0;
  resize: none;
  border-radius: 6px;
}
.refundBox_ span {
  margin-right: 40px;
}
.refundReason_ div {
  margin-bottom: 20px;
}
.refundReason_ div span {
  margin-right: 20px;
}
.el-dialog input::-webkit-input-placeholder {
  color: #999;
}
/* 项目管理模块样式 */
.addVisitingService {
  position: relative;
  top: 0;
  left: 1500px;
  width: 150px;
  height: 30px;
  background-color: #1ab394;
  color: #fff;
  vertical-align: middle;
  padding: 7px 0 15px 25px;
  margin-bottom: 15px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
}
.el-icon-plus {
  position: absolute;
  top: 7px;
  left: 7px;
  font-weight: 900;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 15px;
}

#servebox .box_body .el-card__body {
  padding: 0px !important;
}
.nav .el-card__body {
  padding: 0px !important;
}
.table-stripped thead {
  border-top: 1px solid #dddddd;
  height: 50px !important ;
}
.text-center {
  padding: 15px 0;
}
.form-tip {
  padding: 0 0 20px 30px;
}
.el-form--inline {
  padding-left: 30px;
}
</style>
